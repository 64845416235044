import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import XLSX from "xlsx";
import app from "../firebase/firebase";

export const Data = createContext();

export function Context(props) {
  let allStates = [
    "State",
    "Andaman and Nicobar Islands",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chandigarh",
    "Chhattisgarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Lakshadweep",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Puducherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];
  let specialityArr = [
    "SPECIALITY",
    "GENERAL MEDICINE",
    "CARDIOLOGY",
    "CRITICAL CARE MEDICINE",
    "DERMATOLOGY",
    "NEPHROLOGY",
    "DENTISTRY",
    "DIABETOLOGY",
    "ENT",
    "GASTROENTEROLOGY & HEPATOLOGY",
    "HIV / AIDS",
    "INFECTIOUS DISEASES",
    "MUSCULOSKELETAL DISEASES",
    "NEUROPSYCHIATRY",
    "OBSTETRICS & GYNAECOLOGY",
    "ONCOLOGY",
    "OPHTHALMOLOGY",
    "PEDIATRICS",
    "RESPIRATORY MEDICINE",
    "SURGERY",
    "UROLOGY",
    "OTHER",
  ];
  const [fullname, setFullname] = useState("");
  const [nameError, setNameError] = useState("");
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");
  const [pincode, setPincode] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [clinic, setClinic] = useState("");
  const [clinicError, setClinicError] = useState("");
  const [state, setState] = useState(allStates[0]);
  const [stateError, setStateError] = useState("");
  const [speciality, setSpeciality] = useState(specialityArr[0]);
  const [specialityError, setSepecialityError] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [workshopOne, setWorkshopOne] = useState(false);
  const [workshopTwo, setWorkshopTwo] = useState(false);
  const [workshopThree, setWorkshopThree] = useState(false);
  const [workshopFour, setWorkshopFour] = useState(false);
  const [d1w1, setd1w1] = useState(1);
  const [d2w1, setd2w1] = useState(1);
  const [d1w2, setd1w2] = useState(1);
  const [d2w2, setd2w2] = useState(1);
  const [regiterActive, setRegisterActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [gender, setGender] = useState("Dr.");
  const [pdfOpened, setPdfOpened] = useState(false);
  const [calOptions, setCalOptions] = useState(false);
  const [restrictingData, setRestrictingData] = useState({});
  const [alreadyRegistered, setAlreadyRegistered] = useState(false);
  const [availBoth, setAvailBoth] = useState("");
  const ref = app.firestore().collection("user-data");
  const workshopCountRef = app.firestore().collection("workshopCount");
  const get = app.firestore().collection("restrictions");

  useEffect(() => {
    get.onSnapshot((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setRestrictingData(doc.data());
      });
    });
    // getUserInNoWorkshop();
  }, []);

  const checkAndAdd = (mobile, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const docRef = ref.doc(`${mobile}`);
        const result = await app
          .firestore()
          .runTransaction(async (transaction) => {
            const doc = await transaction.get(docRef);
            if (doc.exists) {
              let err = {
                code: "AlreadyRegistered",
                message: "User is already registered.",
              };
              throw err;
            }
            transaction.set(docRef, data);
            return;
          });
        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  };

  const getUserInNoWorkshop = async () => {
    const ref = app.firestore().collection("user-data").where("day1_workshop1", "==", 0).where("day1_workshop2", "==", 0).where("day2_workshop1", "==", 0).where("day2_workshop2", "==", 0)
    const query = await ref.get()
    if (!query.empty) {
      let data = []
      query.docs.forEach((doc) => {
        data.push(doc.data())
      })
      console.log(data)
      exportFile(data, "ciplaIntenseUserData", `ciplaIntenseUserData.xlsx`)
    } else {
      console.log("no data found!!");
    }
  }

  const exportFile = (data, sheetName, filename) => {
    const wb = XLSX.utils.book_new()
    const wsAll = XLSX.utils.json_to_sheet(data)
    XLSX.utils.book_append_sheet(wb, wsAll, sheetName)
    XLSX.writeFile(wb, filename)
  }


  const getWorkshopCount = async () => {
    const snapshot = await ref.get()
    const docs = snapshot.docs;
    console.log(docs.length)
    let count = {
      day1_workshop1_1: 0,
      day1_workshop1_2: 0,
      day1_workshop2_1: 0,
      day1_workshop2_2: 0,
      day2_workshop1_1: 0,
      day2_workshop1_2: 0,
      day2_workshop2_1: 0,
      day2_workshop2_2: 0,
    }
    docs.forEach(doc => {
      let data = doc.data();
      count = {
        day1_workshop1_1: data.day1_workshop1 === 1 ? count.day1_workshop1_1 + 1 : count.day1_workshop1_1,
        day1_workshop1_2: data.day1_workshop1 === 2 ? count.day1_workshop1_2 + 1 : count.day1_workshop1_2,
        day1_workshop2_1: data.day1_workshop2 === 1 ? count.day1_workshop2_1 + 1 : count.day1_workshop2_1,
        day1_workshop2_2: data.day1_workshop2 === 2 ? count.day1_workshop2_2 + 1 : count.day1_workshop2_2,
        day2_workshop1_1: data.day2_workshop1 === 1 ? count.day2_workshop1_1 + 1 : count.day2_workshop1_1,
        day2_workshop1_2: data.day2_workshop1 === 2 ? count.day2_workshop1_2 + 1 : count.day2_workshop1_2,
        day2_workshop2_1: data.day2_workshop2 === 1 ? count.day2_workshop2_1 + 1 : count.day2_workshop2_1,
        day2_workshop2_2: data.day2_workshop2 === 2 ? count.day2_workshop2_2 + 1 : count.day2_workshop2_2,
      }
    });

    console.log(count)
    workshopCountRef.doc("data").set(count).then(() => {
      console.log('done!!')
    })

  }

  const addUserDetails = (data, mobile) => {
    setLoading(true);

    checkAndAdd(mobile, data)
      .then(() => {
        axios
          .post(
            "https://mail-intense.djvirtualevents.com/sendRegistrationMail",
            data
          )
          .then(() => {
            console.log("done");
            setFullname("");
            setCity("");
            setPincode("");
            setState(allStates[0]);
            setClinic("");
            setSpeciality(specialityArr[0]);
            setMobileNumber("");
            setEmail("");
            setNameError("");
            setCityError("");
            setPincodeError("");
            setStateError("");
            setClinicError("");
            setSepecialityError("");
            setMobileError("");
            setEmailError("");
            setd1w1(1);
            setd2w1(1);
            setd1w2(1);
            setd2w2(1);
            setRegisterActive(false);
            setWorkshopOne(false);
            setWorkshopTwo(false);
            setWorkshopThree(false);
            setWorkshopThree(false);
            setLoading(false);
            setCompleted(true);
            setAlreadyRegistered(false);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        setLoading(false);
        setAlreadyRegistered(true);
      });
  };
  return (
    <Data.Provider
      value={{
        specialityArr,
        allStates,
        addUserDetails,
        fullname,
        setFullname,
        email,
        setEmail,
        mobileNumber,
        setMobileNumber,
        clinic,
        setClinic,
        state,
        setState,
        city,
        setCity,
        speciality,
        setSpeciality,
        pincode,
        setPincode,
        nameError,
        setNameError,
        cityError,
        setCityError,
        pincodeError,
        setPincodeError,
        clinicError,
        setClinicError,
        stateError,
        setStateError,
        specialityError,
        setSepecialityError,
        mobileError,
        setMobileError,
        emailError,
        setEmailError,
        workshopOne,
        setWorkshopOne,
        workshopTwo,
        setWorkshopTwo,
        workshopThree,
        setWorkshopThree,
        workshopFour,
        setWorkshopFour,
        d1w1,
        setd1w1,
        d2w1,
        setd2w1,
        d1w2,
        setd1w2,
        d2w2,
        setd2w2,
        regiterActive,
        setRegisterActive,
        loading,
        completed,
        setCompleted,
        gender,
        setGender,
        pdfOpened,
        setPdfOpened,
        calOptions,
        setCalOptions,
        restrictingData,
        alreadyRegistered,
        setAlreadyRegistered,
        availBoth,
        setAvailBoth,
      }}
    >
      {props.children}
    </Data.Provider>
  );


}
