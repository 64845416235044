import firebase from 'firebase/app';
import 'firebase/firestore';

const app = firebase.initializeApp({
    apiKey: "AIzaSyA9iwkyGnEoxBqyGgzP_WDh57gk7IrP5Jw",
    authDomain: "cipla-intense.firebaseapp.com",
    projectId: "cipla-intense",
    storageBucket: "cipla-intense.appspot.com",
    messagingSenderId: "146751555789",
    appId: "1:146751555789:web:e9cdc64106d280a30f0184",
    measurementId: "G-NL1YHH3Y44"
})

export default app;