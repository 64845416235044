import React, { useContext, useEffect } from "react";
import { Data } from "../context/Context";
import { v4 as uuidv4 } from "uuid";
import background from "../static/background.png";
import ciplaLogo from "../static/ciplaLogo.png";
import frame from "../static/Frame.png";
import intenseLogo from "../static/intenseLogo.png";
import mobilebg from "../static/mobilebg.png";
import calander from "../static/calander_icon.png";
import cross from "../static/cross.png";
import stroke from "../static/stroke.png";
import "./MainContainer.css";

function MainContainer() {
  const {
    specialityArr,
    allStates,
    addUserDetails,
    fullname,
    setFullname,
    email,
    setEmail,
    mobileNumber,
    setMobileNumber,
    clinic,
    setClinic,
    state,
    setState,
    city,
    setCity,
    speciality,
    setSpeciality,
    pincode,
    setPincode,
    nameError,
    setNameError,
    cityError,
    setCityError,
    pincodeError,
    setPincodeError,
    clinicError,
    setClinicError,
    stateError,
    setStateError,
    specialityError,
    setSepecialityError,
    mobileError,
    setMobileError,
    emailError,
    setEmailError,
    workshopOne,
    setWorkshopOne,
    workshopTwo,
    setWorkshopTwo,
    workshopThree,
    setWorkshopThree,
    workshopFour,
    setWorkshopFour,
    d1w1,
    setd1w1,
    d2w1,
    setd2w1,
    d1w2,
    setd1w2,
    d2w2,
    setd2w2,
    regiterActive,
    setRegisterActive,
    loading,
    completed,
    gender,
    setGender,
    pdfOpened,
    setPdfOpened,
    calOptions,
    setCalOptions,
    restrictingData,
    alreadyRegistered,
    setAlreadyRegistered,
    availBoth,
    setAvailBoth,
  } = useContext(Data);

  let contextData = useContext(Data);

  useEffect(() => {
    if (restrictingData.d1w1) {
      if (
        restrictingData.d1w1.option_two.available &&
        restrictingData.d1w1.option_one.available
      ) {
        setAvailBoth("one");
      }
      if (
        restrictingData.d1w2.option_two.available &&
        restrictingData.d1w2.option_one.available
      ) {
        setAvailBoth("one");
      }
      if (
        restrictingData.d2w1.option_two.available &&
        restrictingData.d2w1.option_one.available
      ) {
        setAvailBoth("one");
      }
      if (
        restrictingData.d2w2.option_two.available &&
        restrictingData.d2w2.option_one.available
      ) {
        setAvailBoth("one");
      }
    }
  }, [restrictingData]);
  const isInvalidMobileNumber = () => {
    for (let i = 0; i < mobileNumber.length; i++) {
      if (mobileNumber.charCodeAt(i) < 48 || mobileNumber.charCodeAt(i) > 57) {
        return true;
      }
    }
    return false;
  };
  const isInvalidPinCode = () => {
    for (let i = 0; i < pincode.length; i++) {
      if (pincode.charCodeAt(i) < 48 || pincode.charCodeAt(i) > 57) {
        return true;
      }
    }
    return false;
  };
  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  const setData = () => {
    if (fullname === "") {
      setNameError("* Enter Name");
      return;
    }
    if (email === "") {
      setEmailError("* Enter Email");
      return;
    }
    const newEmail = email.trim().toLowerCase();
    if (!validateEmail(newEmail)) {
      setEmailError("* Invalid Input");
      return;
    }
    if (mobileNumber === "") {
      setMobileError("* Enter Mobile Number");
      return;
    }
    if (isInvalidMobileNumber()) {
      setMobileError("* Not a mobile Number");
      return;
    }

    if (mobileNumber.length !== 10) {
      setMobileError("* Invalid mobile number");
      return;
    }
    if (state === "State") {
      setStateError("* Select State");
      return;
    }
    if (city === "") {
      setCityError("* Enter City");
      return;
    }
    if (pincode === "") {
      setPincodeError("* Enter Pincode");
      return;
    }
    if (isInvalidPinCode()) {
      setPincodeError("* Not a Pincode");
      return;
    }

    if (clinic === "") {
      setClinicError("* Hospital / Clinic name");
      return;
    }
    if (speciality === "SPECIALITY") {
      setSepecialityError("* Select Speciality");
    }
    let day1 = d1w1;
    let day2 = d2w1;
    let day3 = d1w2;
    let day4 = d2w2;
    if (restrictingData.d1w1) {
      if (
        restrictingData.d1w1.option_two.available &&
        !restrictingData.d1w1.option_one.available
      ) {
        day1 = 2;
      }
      if (
        restrictingData.d1w2.option_two.available &&
        !restrictingData.d1w2.option_one.available
      ) {
        day2 = 2;
      }
      if (
        restrictingData.d2w1.option_two.available &&
        !restrictingData.d2w1.option_one.available
      ) {
        day3 = 2;
      }
      if (
        restrictingData.d2w2.option_two.available &&
        !restrictingData.d2w2.option_one.available
      ) {
        day4 = 2;
      }
    }

    if (workshopOne === false) {
      day1 = 0;
      setd1w1(0);
    }

    if (workshopTwo === false) {
      day2 = 0;
      setd1w2(0);
    }

    if (workshopThree === false) {
      day3 = 0;
      setd2w1(0);
    }

    if (workshopFour === false) {
      day4 = 0;
      setd2w2(0);
    }

    const data = {
      _id: uuidv4(),
      name: gender + " " + fullname,
      email: newEmail,
      mobileNumber,
      clinic,
      speciality,
      pincode,
      state,
      city,
      day1_workshop1: day1,
      day1_workshop2: day2,
      day2_workshop1: day3,
      day2_workshop2: day4,
    };
    // console.log(data);
    addUserDetails(data, mobileNumber);
  };

  const D1W1 = (id) => {
    setd1w1(id);
  };
  const D2W1 = (id) => {
    setd2w1(id);
  };
  const D1W2 = (id) => {
    setd1w2(id);
  };
  const D2W2 = (id) => {
    setd2w2(id);
  };

  const toggleWorkshop = (functionName, value) => {
    if (value) {
      setWorkshopOne(false);
      setWorkshopTwo(false);
      setWorkshopThree(false);
      setWorkshopFour(false);
      functionName(true);
    } else {
      functionName(false);
    }
  };

  return (
    <div className="main__container" unselectable="on">
      <div className="left__container">
        <img src={background} alt="" className="background" />
        <img src={mobilebg} alt="" className="mobilebg" />
        <img src={ciplaLogo} alt="" className="logo" />
        <div className="logo__container">
          <div className="inner_container">
            <div className="intense__logo__cont">
              <img src={intenseLogo} alt="" className="intense__logo" />
              <img src={stroke} alt="" className="stroke" />
              <h2 className="new_title">
                <span>Integrating Experience and</span>
                <span>Scientific Evidence</span>
              </h2>
            </div>
            <img src={frame} alt="" className="frame" />
          </div>
        </div>
      </div>
      <div className="right__container">
        {loading === true ? (
          ""
        ) : (
            <div className={`form__container ${completed ? "hide" : ""}`}>
              <h1>Already Registered?</h1>
              <h3 style={{ fontWeight: 'lighter', marginBottom: '1.5rem', textDecoration: 'underline', cursor: 'pointer', }} onClick={(event) => {
                event.preventDefault();
                window.location.href = "https://intense2021.djvirtualevents.com/"
              }}>Click here to login.</h3>
              <h2>NEW REGISTRATION</h2>
              <h4 style={{ fontWeight: 'lighter', marginBottom: '0.5rem' }}>Please enter the following details to register for the sessions:</h4>
              {/* <br></br> */}
              <div className="inner__form">
                <div className="fullname">
                  <select
                    className="gender"
                    value={gender}
                    onChange={(e) => {
                      setGender(e.target.value);
                    }}
                  >
                    <option value="Dr.">Dr.</option>
                    <option value="Mr.">Mr.</option>
                    <option value="Ms.">Ms.</option>
                    <option value="Mrs.">Mrs.</option>
                  </select>
                  <input
                    type="text"
                    value={fullname}
                    placeholder="Full Name"
                    onChange={(e) => {
                      setFullname(e.target.value);
                      setNameError("");
                    }}
                  />
                </div>
                <span>{nameError}</span>
                <input
                  type="email"
                  value={email}
                  placeholder="Email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError("");
                  }}
                />
                <span>{emailError}</span>
                <input
                  type="number"
                  value={mobileNumber}
                  placeholder="Mobile"
                  maxLength="10"
                  onChange={(e) => {
                    setMobileNumber(e.target.value.substring(0, 10));
                    setMobileError("");
                  }}
                />
                <span>{mobileError}</span>
                <select
                  value={state}
                  onChange={(e) => {
                    setState(e.target.value);
                    setStateError("");
                  }}
                >
                  {allStates.map((item, pos) => {
                    return (
                      <option
                        value={item.toUpperCase()}
                        key={pos}
                        className="speciality"
                      >
                        {item.toUpperCase()}
                      </option>
                    );
                  })}
                </select>
                <span>{stateError}</span>
                <input
                  type="text"
                  value={city}
                  placeholder="City"
                  onChange={(e) => {
                    setCity(e.target.value);
                    setCityError("");
                  }}
                />
                <span>{cityError}</span>
                <input
                  type="number"
                  value={pincode}
                  placeholder="Pincode"
                  maxLength="6"
                  onChange={(e) => {
                    setPincode(e.target.value.substring(0, 6));
                    setPincodeError("");
                  }}
                />
                <span>{pincodeError}</span>

                <input
                  type="text"
                  value={clinic}
                  placeholder="Hospital / Clinic"
                  onChange={(e) => {
                    setClinic(e.target.value);
                    setClinicError("");
                  }}
                />
                <span>{clinicError}</span>
                <select
                  value={speciality}
                  onChange={(e) => {
                    setSpeciality(e.target.value);
                    setSepecialityError("");
                  }}
                >
                  {specialityArr.map((item, pos) => {
                    return (
                      <option key={pos} value={item.toUpperCase()}>
                        {item.toUpperCase()}
                      </option>
                    );
                  })}
                </select>
                <span>{specialityError}</span>
                {/* 
              <span className="text">Select your preferred workshop :</span>
              <span className="seat__cnfrm">
                * Seat confirmation is subject to availability.
              </span>
              <span className="seat__cnfrm">
                * Please select any one workshop.
              </span>

              <span
                className="pdf"
                onClick={() => {
                  setPdfOpened(true);
                }}
              >
                Click here for more details
              </span> */}

                {/* <div
                  className={`workShop  ${restrictingData.d1w1
                    ? restrictingData.d1w1.option_one.available ||
                      restrictingData.d1w1.option_two.available
                      ? ""
                      : "notAvailable"
                    : ""
                    }`}
                >
                  <input
                    type="radio"
                    className="checkBox"
                    checked={workshopOne}
                    // onChange={() => setWorkshopOne(!workshopOne)}
                    onChange={() => toggleWorkshop(setWorkshopOne, !workshopOne)}
                  />
                  <div className="title">
                    <div>12th March, Workshop 1</div>
                    <div>3:00 PM - 4:30 PM</div>
                  </div>
                </div>
                {restrictingData.d1w1 ? (
                  !restrictingData.d1w1.option_one.available &&
                    !restrictingData.d1w1.option_two.available ? (
                      <div className="noSeats">* No Seats Available</div>
                    ) : (
                      ""
                    )
                ) : (
                    ""
                  )}
                <div
                  className={`toggle_options ${!workshopOne ? "nonClickable" : ""
                    }`}
                >
                  <div
                    className={`option__one  ${availBoth} ${restrictingData.d1w1
                      ? restrictingData.d1w1.option_one.available &&
                        !restrictingData.d1w1.option_two.available
                        ? "selected"
                        : restrictingData.d1w1.option_one.available &&
                          restrictingData.d1w1.option_two.available
                          ? d1w1 === 1
                            ? "selected"
                            : ""
                          : "finished"
                      : ""
                      }`}
                    onClick={() => {
                      if (restrictingData.d2w1) {
                        if (restrictingData.d1w1.option_one.available) {
                          D1W1(1);
                        }
                      }
                    }}
                  >
                    <p className={`wks ${restrictingData.d1w1 &&
                      !restrictingData.d1w1.option_one.available && 'wks-noAva'}`}>
                      <span>Antibiotic Stewardship Bench to Bedside</span>
                      {
                        restrictingData.d1w1 &&
                        !restrictingData.d1w1.option_one.available &&
                        <span className="noAva">*Registrations for this session are full</span>
                      }
                    </p>
                  </div>
                  <div
                    className={`option__one ${restrictingData.d1w1
                      ? !restrictingData.d1w1.option_one.available &&
                        restrictingData.d1w1.option_two.available
                        ? "selected"
                        : restrictingData.d1w1.option_one.available &&
                          restrictingData.d1w1.option_two.available
                          ? d1w1 === 2
                            ? "selected"
                            : ""
                          : "finished"
                      : ""
                      }`}
                    onClick={() => {
                      if (restrictingData.d2w1) {
                        if (restrictingData.d1w1.option_two.available) {
                          D1W1(2);
                        }
                      }
                    }}
                  >
                    <p className={`wks ${restrictingData.d1w1 &&
                      !restrictingData.d1w1.option_two.available && 'wks-noAva'}`}>
                      <span>Ventilation Strategies &amp; Advanced CPR</span>
                      {
                        restrictingData.d1w1 &&
                        !restrictingData.d1w1.option_two.available &&
                        <span className="noAva">*Registrations for this session are full</span>
                      }
                    </p>
                  </div>
                </div>

                <div
                  className={`workShop ${restrictingData.d1w2
                    ? restrictingData.d1w2.option_one.available ||
                      restrictingData.d1w2.option_two.available
                      ? ""
                      : "notAvailable"
                    : ""
                    }`}
                >
                  <input
                    type="radio"
                    className="checkBox"
                    checked={workshopTwo}
                    // onChange={() => {setWorkshopTwo(!workshopTwo);}}
                    onChange={() => toggleWorkshop(setWorkshopTwo, !workshopTwo)}
                  />
                  <div className="title">
                    <div>12th March, Workshop 2</div>
                    <div>4:30 PM - 6:00 PM</div>
                  </div>
                </div>
                {restrictingData.d1w1 ? (
                  !restrictingData.d1w2.option_one.available &&
                    !restrictingData.d1w2.option_two.available ? (
                      <div className="noSeats">* No Seats Available</div>
                    ) : (
                      ""
                    )
                ) : (
                    ""
                  )}

                <div
                  className={`toggle_options ${!workshopTwo ? "nonClickable" : ""
                    }`}
                >
                  <div
                    onClick={() => {
                      if (restrictingData.d2w1) {
                        if (restrictingData.d1w2.option_one.available) {
                          D1W2(1);
                        }
                      }
                    }}
                    className={`option__one ${availBoth} ${restrictingData.d1w2
                      ? restrictingData.d1w2.option_one.available &&
                        !restrictingData.d1w2.option_two.available
                        ? "selected"
                        : restrictingData.d1w2.option_one.available &&
                          restrictingData.d1w2.option_two.available
                          ? d1w2 === 1
                            ? "selected"
                            : ""
                          : "finished"
                      : ""
                      }`}
                  >
                    <p className={`wks ${restrictingData.d1w2 &&
                      !restrictingData.d1w2.option_one.available && 'wks-noAva'}`}>
                      <span>Antifungal stewardship Pearls of wisdom</span>
                      {
                        restrictingData.d1w2 &&
                        !restrictingData.d1w2.option_one.available &&
                        <span className="noAva">*Registrations for this session are full</span>
                      }
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      if (restrictingData.d2w1) {
                        if (restrictingData.d1w2.option_two.available) {
                          D1W2(2);
                        }
                      }
                    }}
                    className={`option__one ${restrictingData.d1w2
                      ? !restrictingData.d1w2.option_one.available &&
                        restrictingData.d1w2.option_two.available
                        ? "selected"
                        : restrictingData.d1w2.option_one.available &&
                          restrictingData.d1w2.option_two.available
                          ? d1w2 === 2
                            ? "selected"
                            : ""
                          : "finished"
                      : ""
                      }`}
                  >
                    <p className={`wks ${restrictingData.d1w2 &&
                      !restrictingData.d1w2.option_two.available && 'wks-noAva'}`}>
                      <span>RRT- Prevention to management</span>
                      {
                        restrictingData.d1w2 &&
                        !restrictingData.d1w2.option_two.available &&
                        <span className="noAva">*Registrations for this session are full</span>
                      }
                    </p>
                  </div>
                </div>

                <div
                  className={`workShop ${restrictingData.d2w1
                    ? restrictingData.d2w1.option_one.available ||
                      restrictingData.d2w1.option_two.available
                      ? ""
                      : "notAvailable"
                    : ""
                    }`}
                >
                  <input
                    type="radio"
                    className="checkBox"
                    checked={workshopThree}
                    // onChange={() => setWorkshopThree(!workshopThree)}
                    onChange={() => toggleWorkshop(setWorkshopThree, !workshopThree)}
                  />
                  <div className="title">
                    <div>13th March, Workshop 1</div>
                    <div>3:00 PM - 4:30 PM</div>
                  </div>
                </div>
                {restrictingData.d1w1 ? (
                  !restrictingData.d2w1.option_one.available &&
                    !restrictingData.d2w1.option_two.available ? (
                      <div className="noSeats">* No Seats Available</div>
                    ) : (
                      ""
                    )
                ) : (
                    ""
                  )}
                <div
                  className={`toggle_options ${!workshopThree ? "nonClickable" : ""
                    }`}
                >
                  <div
                    onClick={() => {
                      if (restrictingData.d2w1) {
                        if (restrictingData.d2w1.option_one.available) {
                          D2W1(1);
                        }
                      }
                    }}
                    className={`option__one ${availBoth} ${restrictingData.d2w1
                      ? restrictingData.d2w1.option_one.available &&
                        !restrictingData.d2w1.option_two.available
                        ? "selected"
                        : restrictingData.d2w1.option_one.available &&
                          restrictingData.d2w1.option_two.available
                          ? d2w1 === 1
                            ? "selected"
                            : ""
                          : "finished"
                      : ""
                      }`}
                  >
                    <p className={`wks ${restrictingData.d2w1 &&
                      !restrictingData.d2w1.option_one.available && 'wks-noAva'}`}>
                      <span className="longest__text">
                        Research Methodology; How to read paper; How to write
                        paper
                    </span>
                      {
                        restrictingData.d2w1 &&
                        !restrictingData.d2w1.option_one.available &&
                        <span className="noAva">*Registrations for this session are full</span>
                      }
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      if (restrictingData.d2w1) {
                        if (restrictingData.d2w1.option_two.available) {
                          D2W1(2);
                        }
                      }
                    }}
                    className={`option__one ${restrictingData.d2w1
                      ? !restrictingData.d2w1.option_one.available &&
                        restrictingData.d2w1.option_two.available
                        ? "selected"
                        : restrictingData.d2w1.option_one.available &&
                          restrictingData.d2w1.option_two.available
                          ? d2w1 === 2
                            ? "selected"
                            : ""
                          : "finished"
                      : ""
                      }`}
                  >
                    <p className={`wks ${restrictingData.d2w1 &&
                      !restrictingData.d2w1.option_two.available && 'wks-noAva'}`}>
                      <span>Antibiotic Stewardship Bench to Bedside</span>
                      {
                        restrictingData.d2w1 &&
                        !restrictingData.d2w1.option_two.available &&
                        <span className="noAva">*Registrations for this session are full</span>
                      }
                    </p>
                  </div>
                </div>

                <div
                  className={`workShop ${restrictingData.d2w2
                    ? restrictingData.d2w2.option_one.available ||
                      restrictingData.d2w2.option_two.available
                      ? ""
                      : "notAvailable"
                    : ""
                    }`}
                >
                  <input
                    type="radio"
                    className="checkBox"
                    checked={workshopFour}
                    // onChange={() => setWorkshopFour(!workshopFour)}
                    onChange={() => toggleWorkshop(setWorkshopFour, !workshopFour)}
                  />
                  <div className="title">
                    <div>13th March, Workshop 2</div>
                    <div>4:30 PM - 6:00 PM</div>
                  </div>
                </div>
                {restrictingData.d1w1 ? (
                  !restrictingData.d2w2.option_one.available &&
                    !restrictingData.d2w2.option_two.available ? (
                      <div className="noSeats">* No Seats Available</div>
                    ) : (
                      ""
                    )
                ) : (
                    ""
                  )}
                <div
                  className={`toggle_options ${!workshopFour ? "nonClickable" : ""
                    }`}
                >
                  <div
                    onClick={() => {
                      if (restrictingData.d2w1) {
                        if (restrictingData.d2w2.option_one.available) {
                          D2W2(1);
                        }
                      }
                    }}
                    className={`option__one ${availBoth} ${restrictingData.d2w1
                      ? restrictingData.d2w2.option_one.available &&
                        !restrictingData.d2w2.option_two.available
                        ? "selected"
                        : restrictingData.d2w2.option_one.available &&
                          restrictingData.d2w2.option_two.available
                          ? d2w2 === 1
                            ? "selected"
                            : ""
                          : "finished"
                      : ""
                      }`}
                  >
                    <p className={`wks ${restrictingData.d2w2 &&
                      !restrictingData.d2w2.option_one.available && 'wks-noAva'}`}>
                      <span>
                        End of Life – Legal ethical dilemmas and solutions
                    </span>
                      {
                        restrictingData.d2w2 &&
                        !restrictingData.d2w2.option_one.available &&
                        <span className="noAva">*Registrations for this session are full</span>
                      }
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      if (restrictingData.d2w1) {
                        if (restrictingData.d2w2.option_two.available) {
                          D2W2(2);
                        }
                      }
                    }}
                    className={`option__one ${restrictingData.d2w1
                      ? !restrictingData.d2w2.option_one.available &&
                        restrictingData.d2w2.option_two.available
                        ? "selected"
                        : restrictingData.d2w2.option_one.available &&
                          restrictingData.d2w2.option_two.available
                          ? d2w2 === 2
                            ? "selected"
                            : ""
                          : "finished"
                      : ""
                      }`}
                  >
                    <p className={`wks ${restrictingData.d2w2 &&
                      !restrictingData.d2w2.option_two.available && 'wks-noAva'}`}>
                      <span>Antifungal stewardship Pearls of wisdom</span>{" "}
                      {
                        restrictingData.d2w2 &&
                        !restrictingData.d2w2.option_two.available &&
                        <span className="noAva">*Registrations for this session are full</span>
                      }
                    </p>
                  </div>
                </div> */}

                <div className="workShop last__workshop">
                  <input
                    type="checkbox"
                    className="checkBox last__checkbox"
                    checked={regiterActive}
                    onChange={() => setRegisterActive(!regiterActive)}
                  />
                  <p className="permit">
                    I, permit Cipla to send me event updates on Email and mobile
                    number.
                </p>
                </div>
                <p className="disclaimer">
                  <b>Disclaimer</b>: An initiative by Cipla for knowledge
                upgradation only. Access to this session is intended strictly
                for Registered Medical Practitioners, not for mass circulation.
                The views expressed by individual doctor/ speaker are their
                independent opinion and is only for information purpose. This
                initiative/ session is NOT intended towards any product
                promotion and any advice regarding the management of any medical
                condition is totally in the discretion of the concerned doctor/
                Registered Medical Practitioner.
              </p>
              </div>
              <div className="register__button">
                {alreadyRegistered ? <span>* User Alredy Registered</span> : ""}
                <button
                  onClick={setData}
                  disabled={!regiterActive ? true : false}
                  className={`${!regiterActive ? "disabled" : ""}`}
                >
                  Register
              </button>
              </div>
            </div>
          )}

        {loading === true ? (
          <div className="loading__container">
            <div id="blocker" className="blocker">
              <div className="lds-dual-ring"></div>
            </div>
          </div>
        ) : (
            <div className={`thankyou__container ${completed ? "show" : ""}`}>
              <div className="center__box">
                <h2 className="thank__you__head">Thank You For Registration</h2>

                <p className={`message ${calOptions ? "extra" : ""}`}>
                  <span className="first_line">
                    <h2 style={{ fontWeight: 'lighter', marginBottom: '1.5rem', textDecoration: 'underline', cursor: 'pointer', }} onClick={(event) => {
                      event.preventDefault();
                      window.location.href = "https://intense2021.djvirtualevents.com/"
                    }}>Click here to login.</h2>
                  </span>
                  {/* <span>button below</span> */}
                </p>

                {/* <div className="calander__btn">
                  <a
                    target="_blank"
                    href="https://calendar.google.com/calendar/u/0/r/eventedit?text=Cipla+Intense+2021&dates=20210312/20210314&uid=603e0bcead488100a31a5cb1&details=Link:+https://intense2021.djvirtualevents.com/&location=intense2021.djvirtualevents.com"
                  >
                    <button>
                      <img src={calander} alt="" className="calander__img" />
                      <p>Add to Calender</p>
                    </button>
                  </a>
                </div> */}

              </div>
            </div>
          )}
      </div>
      <div
        className={pdfOpened ? "pdf__viewer" : "notOpened"}
        onClick={() => {
          setPdfOpened(false);
        }}
      >
        <img src={cross} alt="" className="cross" />
        <iframe
          src="/web/viewer.html?file=%2fassets%2fpdf%2fdetails.pdf"
          className="main__pdf"
        ></iframe>
      </div>
      <div className="rotate-screen-popup">
        <div id="rotate">
          <div className="phone"></div>
          <div className="message">Please rotate your device!</div>
        </div>
      </div>
    </div>
  );
}

export default MainContainer;
